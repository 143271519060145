import { SelectionState, SelectionType, SelectionValue } from "@/models/common";

export default abstract class ImageManager {
  private static verifyFileSize(bytes: number, type: SelectionType): boolean {
    const factor = Math.pow(1024, type === SelectionType.Image ? 1 : 2);
    const size = bytes / factor;
    return size <= (type === SelectionType.Image ? 512 : 10);
  }

  static getFile(
    files: FileList | null,
    state?: SelectionState
  ): SelectionValue {
    const file =
      state === SelectionState.Image || state === SelectionState.Video
        ? files?.[0]
        : undefined;
    return {
      file,
      url: file ? URL.createObjectURL(file) : "",
    };
  }

  static checkFile(
    input: HTMLInputElement,
    strict = true
  ): SelectionState | undefined {
    const allowedImgExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    const allowedVideoExtensions = /(\.mp4)$/i;

    if (input.files?.length) {
      let selectionType = SelectionType.None;
      if (allowedImgExtensions.exec(input.value)) {
        selectionType = SelectionType.Image;
      } else if (allowedVideoExtensions.exec(input.value)) {
        selectionType = SelectionType.Video;
      } else {
        return SelectionState.NotValid;
      }
      if (selectionType) {
        const file = input.files[0];
        const fileOversized = !ImageManager.verifyFileSize(
          file.size,
          selectionType
        );
        if (strict && fileOversized) {
          return SelectionState.TooBig;
        } else {
          return selectionType === SelectionType.Image
            ? SelectionState.Image
            : SelectionState.Video;
        }
      } else {
        return SelectionState.NotValid;
      }
    }
  }

  static getMediaErrorMessage(state: SelectionState): string {
    if (state === SelectionState.TooBig) {
      return "El tamaño del archivo supera el máximo permitido... Imágenes:512kB. Videos: 10MB";
    } else if (state === SelectionState.Video) {
      return "El archivo seleccionado no corresponde a una imagen. Las extensiones permitidas son: *.jpg, *.jpeg, *.png.";
    } else {
      return "El archivo seleccionado no corresponde a una imagen o un video. Las extensiones permitidas son: *.jpg, *.jpeg, *.png, *.mp4.";
    }
  }
}
